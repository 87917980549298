.print-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.show-on-print {
  display: none;
}

.show-on-print-important {
  display: none !important;
}



@media print {

  body {
    background-color: #fff !important;
    -webkit-print-color-adjust: exact !important;
  }

  #MainContainer {
    padding: 0 !important;
    background-color: #fff !important;
  }

  #LeftDrawer {
    display: none !important;
  }

  .hide-on-print {
    display: none !important;
  }

  .show-on-print, .show-on-print-important {
    display: flex !important;
  }


  .print-version {
    box-shadow: none !important;
    font-size: 10px;

    & .root-base-card {
      box-shadow: none !important;
      border: 1px solid #d9e4e8;
      overflow: hidden;
      max-height: none;
    }

    & .manage-columns {
      display: none;
    }

    & thead {
      font-size: 12px;
      font-weight: 500;
      color: #919a9c;
      & th {
        padding: 6px 0 6px 25px !important;
      }
    }
    
    & tbody {
      & td {
        padding: 6px 0 6px 25px !important;
      }
    }

    & .group-table-row {
      vertical-align: top;
      & td {
        padding: 10px 10px 10px 22px !important;
      }
    }


    & .print-root {
      height: unset;
    }

    & .row-for-print {
      max-width: 250px;
      overflow: auto;
      white-space: normal;
      cursor: default;
    }

    & .table-header-print {
      min-height: 23px;

      & .remove-sort {
        display: none;  
      }
    }


    & .table-sum-row-print {
      height: 30px;
    }
  }

  .tracker-header-print {
    margin-top: 15px;
    border: 1px solid #d9e4e8 !important;
    box-shadow: none !important;
    padding: 15px 0 !important;
  }
}
