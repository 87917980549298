@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-toolbar {
    .fc-header-toolbar {
        max-width: 50%;
    }

    h2 {  
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #233539;
}
}
.fc-left {
    display: flex;
    align-items: center;
}

.fc-button-primary {
    background-color: unset;
    border: unset;

    &:hover {
        background-color: unset;
    }
    &:not(:disabled):active {
        background-color: unset;
        border: unset;
        box-shadow: unset;
    }
    &:not(:disabled):active:focus {
        box-shadow: unset;
    }
    &:focus {
        box-shadow: unset;
    }
}
.fc-icon {
    color: #4192ec;
    opacity: 1 !important;
}

.fc-more-popover {
    box-shadow: 0 0 6px 0 rgba(189, 194, 196, 0.5);
    border: unset !important;
}

.fc-more {
    font-size: 11px !important;
}

.fc-day-header {
    background-color: #f7f9fa;
    text-align: left !important;
    padding: 5px 10px !important;
    color: #657274;
    font-weight: 600;
}

.fc-unthemed td.fc-today {
    background-color: unset;
}
.fc-today {
    & .fc-day-number {
        color: #ffffff;
        border-radius: 50%;
        text-align: center;
        font-weight: 300;
        position: relative;
        z-index: 2;

        &::before {
            content: '';
            position: absolute;
            background-color: #4192ec;
            border-radius: 50%;
            z-index: -1;
            top: 50%;
            left: 50%;
            width: 80%;
            height: 80%;
            transform: translate(-50%, -50%);
        }
    }
}

.fc-content {
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.45;
    letter-spacing: normal;
    color: #657274;
    cursor: pointer;
}

.fc-day {
    &-number {
        font-family: Montserrat;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #233539;
        font-weight: 500;
        padding: 10px !important;
        width: 12px;
        text-align: center;
    }
}

.fc {
    &-widget {
        &-content {
            &:last-child:first-child {
                border-top: 0 !important;
            }
        }
        &-header {
            border-bottom: 0 !important;
        }
    }

    &-more {
        padding-left: 5px;
        color: #4192ec;
    }

    &-popover {
        & .fc {
            &-title {
                
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 400;
                line-height: 1.6;
                margin: 0;
            }
            &-header {
                padding: 6px 10px;
                background-color: #f7f9fa !important;
            }
            &-close {
                font-size: 20px !important;
            }
            &-event-container {
                padding: 5px 0 10px;
            }
        }
    }

    
}