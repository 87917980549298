html,
body,
#root,
#root > div,
#root > div > div:not(.MuiSnackbar-root) {
  font-family: 'Montserrat';
  font-weight: normal;
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  background-color: #f7f9fa;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* body::-webkit-scrollbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 100%;
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  height: 30px;
  border-radius: 20px;
  border: 7px solid transparent;
  background-color: #dadfe2;
  background-clip: padding-box;
}
body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
} */